import React from 'react';
import { useState } from 'react';
import { sendEmail } from '../Email Services/Email';
import Swal from "sweetalert2";


const Modal = ({ showModal, handleClose }) => {
const[name , setName] = useState("");
const[email , setEmail] = useState("");
const[message , setMessage] = useState("");
const [status, setStatus] = useState("");
  if (!showModal) return null;

  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    sendEmail(templateParams)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setStatus("Email sent successfully!");

        // Triggering SweetAlert2 for success
        Swal.fire({
          icon: "success",
          title: "Email Sent",
          text: "Your email has been sent successfully!",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline", 
          },
          background: "#000",
          color: "#fff",
          confirmButtonColor: "#98BC62",
        });

        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setStatus("Failed to send email. Please try again later.");

        // Triggering SweetAlert2 for error
        Swal.fire({
          icon: "error",
          title: "Failed to Send",
          text: "Failed to send email. Please try again later.",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline", 
          },
        });
      });
  };

  console.log(status);


  // Inline styles for the modal
  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    background: '#000',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
    position: 'relative',
    border : '2px solid #98BC62'
  };

  const modalCloseBtnStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    background: 'transparent',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#fff',
  };

//   const modalHeadingStyle = {
//     textAlign: 'center',
//     marginBottom: '10px',
//   };

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const modalSubheadingStyle = {
    textAlign: 'center',
    marginBottom: '25px',
    fontSize: '28px',
    color: '#fff',
    fontFamily: 'Calibri',
  };

  const formGroupStyle = {
    marginBottom: '20px',
  };

  const formControlStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid gray',
    borderRadius: '4px',
    backgroundColor: '#000',
    color: '#fff',
    fontSize: '16px',
    outline: 'none',
    transition: 'border-color 0.3s ease',

  };

  const btnPrimaryStyle = {
    backgroundColor: 'transparent',
    color: '#fff',
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    border: '2px solid #98BC62',
    borderRadius: '10px',
    
    
  };

  const btnPrimaryHoverStyle = {
    backgroundColor: '#98BC62',
  };

 

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <button
          style={modalCloseBtnStyle}
          onClick={handleClose}
        >
          &times;
        </button>
        {/* <h2 style={modalHeadingStyle}>Contact Us</h2> */}
        <h3 style={modalSubheadingStyle}>We'd love to hear from you</h3>
        <form onSubmit={handleSubmit}>
          <div style={formGroupStyle}>
            <input
              type="text"
              style={formControlStyle}
              required
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div style={formGroupStyle}>
            <input
              type="email"
              style={formControlStyle}
              required
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={formGroupStyle}>
            <textarea
              style={formControlStyle}
              rows="4"
              placeholder="Your Message"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div style={buttonContainerStyle}>
            <button
              type="submit"
              style={btnPrimaryStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = btnPrimaryHoverStyle.backgroundColor}
              onMouseOut={e => e.currentTarget.style.backgroundColor = btnPrimaryStyle.backgroundColor}
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
