import React from 'react';

const Loader = () => {
  // Inline styles for loader elements
  const loaderContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#000',
    color: '#2c3e50', 
  };

  const spinnerStyle = {
    position: 'relative',
    width: '60px',
    height: '60px',
    marginBottom: '15px', 
  };

  const bounceStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: '#98BC62',
    opacity: 0.6,
    position: 'absolute',
    top: 0,
    left: 0,
    animation: 'bounce 2s infinite ease-in-out',
  };

  const bounceDelayStyle = {
    ...bounceStyle,
    animationDelay: '-1s',
  };

  return (
    <div style={loaderContainerStyle}>
      <div style={spinnerStyle}>
        <div style={bounceStyle}></div>
        <div style={bounceDelayStyle}></div>
      </div>
      <h4 style={{color: 'White', alignSelf: 'center', alignItems: 'center', display: 'flex'}}>Loading...</h4>
    </div>
  );
};

// Keyframes for bounce animation using inline <style> tag
const keyframes = `
@keyframes bounce {
  0%, 100% { transform: scale(0); }
  50% { transform: scale(1); }
}
`;

// Append the keyframes to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = keyframes;
document.head.appendChild(styleSheet);

export default Loader;
